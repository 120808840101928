<template>
  <div class="TwoLevel">
    <div class="twoLevel_top">
      <img src="../assets/image/homeLogo.png" />
    </div>
    <div class="two_level_container">
      <router-view></router-view>
    </div>
    <div class="footer">
      <div>©COPYRIGHT 2019-2023ALL RIGHTS RESERVEDHAIMA MEDIA</div>
      <ul class="app_link">
        <li>
          <a href="https://www.tiktok.com/@haimamedia_official" target="_blank"></a>
        </li>
        <li>
          <a href="https://www.youtube.com/channel/UCdYjLQIFkJcNDbtO-yY6ftA" target="_blank"></a>
        </li>
        <li>
          <router-link to="/contactUs"></router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    setup() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  };

</script>
<style lang="less" scoped>
  .TwoLevel {
    padding-top: 1rem;
    background: #e3e3e3;
    position: relative;
    min-height: calc(100vh);
    box-sizing: border-box;
    padding-bottom: 2.1rem;

    .twoLevel_top {
      width: 7.5rem;
      height: 2.4rem;
      padding: 0.65rem 2.65rem 0;
      box-sizing: border-box;
      background: #2d2d2d;

      img:nth-child(1) {
        width: 2.2rem;
      }
    }

    .footer {
      position: absolute;
      bottom: 0;
      width: 7.5rem;
      height: 2.1rem;
      background: #000000;
      color: #fff;
      margin-top: 0.34rem;
      text-align: center;
      line-height: 1rem;
      font-size: .24rem;

      .app_link {
        display: flex;
        justify-content: center;

        li {
          a {
            display: block;
            width: 0.65rem;
            height: 0.65rem;
            background: url(../assets/image/homeLink.png) no-repeat;
            background-size: 455%;
          }
        }

        li:nth-child(2) {
          margin-left: 0.49rem;

          a {
            background-position: -2.3rem 0;
          }
        }

        li:nth-child(3) {
          margin-left: 0.49rem;

          a {
            background-position: -1.15rem 0;
          }
        }
      }
    }
  }

</style>
